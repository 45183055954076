import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/SearchPageV2.css';

const ITEMS_TO_SHOW = 12;

function SearchPage() {
  const [displayedFiles, setDisplayedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);

  useEffect(() => {
    const fetchRecentFiles = async () => {
      setLoading(true);
      setError(null);
      try {
        let url = new URL('https://oovmxcx0n0.execute-api.us-east-1.amazonaws.com/v1/audio-categories');
        const params = new URLSearchParams();
        params.append('limit', ITEMS_TO_SHOW.toString());
        params.append('type', 'recent');
        url.search = params.toString();

        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch recent files: ${response.status}`);
        }

        const data = await response.json();
        setDisplayedFiles(data.items.slice(0, ITEMS_TO_SHOW));
      } catch (err) {
        setError(`Failed to fetch recent files. ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentFiles();
  }, []);

  // Function to capitalize the first letter of each word, excluding letters after apostrophes
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char, index) => {
      if (index > 0 && str[index - 1] === "'") {
        return char.toLowerCase();
      }
      return char.toUpperCase();
    });
  };

  return (
    <div className="search-page">
      <Helmet>
        <title>Latest Audio Content - Biglio</title>
        <meta name="description" content="Discover the latest audio content from creators." />
      </Helmet>

      <h1>{capitalizeFirstLetterOfEachWord("listen to what's trending")}</h1>
      
      <div className="content-section">
        {error && <p className="error-message">{error}</p>}
        {loading && <div className="loading-spinner">Loading...</div>}
        <div className="content-grid">
  {displayedFiles.map((item, index) => {
    const isHovered = hoveredId === item.id;
    const gradientIntensity = 0.7 + (index % 3) * 0.1;
    
    return (
      <a
        key={item.id || item.metadataId}
        href={`/channel/${item.username}`}
        className="content-tile-link"
        onMouseEnter={() => setHoveredId(item.id || item.metadataId)}
        onMouseLeave={() => setHoveredId(null)}
      >
        <div className="content-tile">
          <div 
            className="content-background"
            style={{
              background: `linear-gradient(45deg, 
                rgba(107, 77, 230, ${gradientIntensity}) 0%,
                rgba(155, 108, 255, ${gradientIntensity - 0.1}) 100%)`
            }}
          />
          <div className="content-details">
            <h3 className="content-title">
              {capitalizeFirstLetterOfEachWord(item.topic || 'Untitled')}
            </h3>
            {item.summary && (
              <p className="content-summary">
                {item.summary}
              </p>
            )}
            <div className={`channel-link ${isHovered ? 'show' : ''}`}>
              <div className="channel-avatar">
                {item.username?.charAt(0)}
              </div>
              <span className="channel-name">@{item.username}</span>
            </div>
          </div>
        </div>
      </a>
    );
  })}
</div>
        {!loading && displayedFiles.length === 0 && (
          <p className="no-results">No content found</p>
        )}
      </div>
    </div>
  );
}

export default SearchPage;