import React, { useState, useRef } from 'react';  
import { useNavigate } from 'react-router-dom';
import SignInModal from './SignInModal';
import { Play, Pause, Sparkles } from 'lucide-react';
import '../styles/HomePageV4.css';
import emailjs from 'emailjs-com';

const HomePage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleStartCreating = () => {
    navigate('/create');
  };

  const sendFeedback = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xvye4hp', 'template_3puhles', e.target, 'lOgEREfAjQAgFES-G')
      .then((result) => {
          console.log(result.text);
          alert('Feedback sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send feedback. Please try again.');
      });

    e.target.reset();
  };

  return (
    <div className="home-container">
      <div className="inner-container">
        <main>
          <section className="hero-section">
            <div className="content-wrapper" style={{ justifyContent: 'flex-start', paddingLeft: '0' }}>
              <div className="left-section">
                <h2 className="headline">
                  Bite Size
                  <br />
                  <span className="gradient-text">Learning</span>
                </h2>
                <p className="hero-description">
                  Listen. Create. Share.
                </p>
                <button 
                  className="cta-button"
                  onClick={handleStartCreating}
                >
                  Start Creating
                </button>
              </div>
              
              <div className="right-section">
                <div className="audio-player-card">
                  <div className="audio-header">
                    <div className="icon-box">
                      <Sparkles className="icon" />
                    </div>
                    <div>
                      <h3 className="audio-title">What is BIGLIO?</h3>
                      <p className="audio-duration">1:30 mins</p>
                    </div>
                  </div>

                  <audio 
                    ref={audioRef}
                    src="https://storage-audio-file.s3.amazonaws.com/audio/What-is-biglio.mp3"
                    onEnded={() => setIsPlaying(false)}
                  />

                  <div className="waveform-container">
                    <div className="waveform">
                      {[...Array(50)].map((_, i) => (
                        <div
                          key={i}
                          style={{
                            height: `${Math.random() * 100}%`,
                            opacity: isPlaying ? 1 : 0.5,
                          }}
                          className="wave-bar"
                        />
                      ))}
                    </div>
                  </div>

                  <button
                    onClick={togglePlay}
                    className="play-button"
                  >
                    {isPlaying ? <Pause className="icon" /> : <Play className="icon" />}
                    {isPlaying ? 'Pause' : 'Play'}
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="beta-message">
            <p>
              🚀  Welcome to Biglio Beta! We're building something special here, and you're among the first to explore it. Sign up now (by clicking the top right - it's easy & free!) You might spot a few quirks (we like to call them "features in training"), that's what makes being an early user exciting. Test it, experiment, and let us know what you think. Your feedback will help shape Biglio's future – every bug report, feature suggestion, or "what if" idea helps make Biglio better for everyone. <br></br>Join our community of early pioneers and be part of something big [ lio ]!
            </p>
          </section>

          <section className="feedback-form">
            <h3>Send Us Your Feedback</h3>
            <form onSubmit={sendFeedback}>
              <input type="text" name="from_name" placeholder="Your name" required />
              <textarea name="message" placeholder="Your feedback" required></textarea>
              <input type="email" name="user_email" placeholder="Your email" required />
              <button type="submit">Send Feedback</button>
            </form>
          </section>
        </main>

        <SignInModal isOpen={false} onClose={() => {}} />
      </div>
    </div>
  );
};

export default HomePage;