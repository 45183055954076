import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FlexibleAudioPlayer from './FlexibleAudioPlayer';
import SignInModal from './SignInModal';
import '../styles/AudioPlayerPageV2.css';

const AudioPlayerPage = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const audioUrl = urlParams.get('audioUrl');
    const topic = urlParams.get('topic');
    const summary = urlParams.get('summary');
    const id = urlParams.get('id');
    const username = urlParams.get('username');

    // Log the parameters for debugging
    console.log('URL Parameters:', {
      audioUrl,
      topic,
      summary,
      id,
      username
    });

    if (!audioUrl || !topic) {
      console.error('Missing necessary audio data in the URL');
      return;
    }

    const audioData = {
      audioUrl: decodeURIComponent(audioUrl),
      topic: decodeURIComponent(topic),
      id: id ? decodeURIComponent(id) : null,
      username: username || '',
    };

    // Only add summary if it exists and has content
    if (summary && summary.trim()) {
      audioData.summary = decodeURIComponent(summary);
    }

    setAudioFile(audioData);

    // Log the processed audio file data
    console.log('Processed Audio Data:', audioData);
  }, [location, params]);

  const handleSignUp = () => {
    setIsSignUpModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSignUpModalOpen(false);
  };

  return (
    <div className="audio-player-page">
      {audioFile ? (
        <FlexibleAudioPlayer 
          tracks={[audioFile]}
        />
      ) : (
        <div className="error-message">Audio data is missing or invalid.</div>
      )}
      <div className="additional-content">
        <p className="blurb">
          Listen to more content on Biglio.
        </p>
        <button onClick={handleSignUp} className="sign-up-button">Sign up now!</button>
      </div>
      {isSignUpModalOpen && (
        <SignInModal
          isOpen={isSignUpModalOpen}
          onClose={handleCloseModal}
          initialView="signup"
        />
      )}
    </div>
  );
};

export default AudioPlayerPage;