import React, { useState, useEffect } from 'react';
import { Globe, ExternalLink, Edit2, X, Check, Link2 } from 'lucide-react';
import '../styles/ChannelHeader.css';

const socialPlatforms = [
  { id: 'twitter', label: 'X' },
  { id: 'facebook', label: 'FB' },
  { id: 'instagram', label: 'IG' },
  { id: 'linkedin', label: 'IN' }
];

const validators = {
  url: (url) => {
    if (!url) return true;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  },
  bio: (text) => {
    if (!text) return true;
    const stripped = text.trim();
    return stripped.length >= 50 && stripped.length <= 400;
  },
  location: (text) => {
    if (!text) return true;
    return text.trim().length <= 100;
  }
};

const DEFAULT_AVATAR = "https://s3.us-west-2.amazonaws.com/biglio.com/static/media/default-avatar.png";

const ChannelHeader = ({
  user,
  playlists,
  onPlaylistChange,
  playlistName,
  isOwner = false,
  onError
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  
  const [profileData, setProfileData] = useState({
    bio: '',
    location: '',
    website: '',
    socials: {
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: ''
    }
  });

  const [editState, setEditState] = useState(profileData);

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = user?.username;
      if (!userId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile?userId=${userId}`
        );

        let normalizedData;
        
        if (!response.ok) {
          // If user doesn't exist in DB yet, use default empty profile
          normalizedData = {
            bio: '',
            location: '',
            website: '',
            socials: {
              twitter: '',
              facebook: '',
              instagram: '',
              linkedin: ''
            }
          };
        } else {
          const data = await response.json();
          // Normalize the data whether it exists or not
          normalizedData = {
            bio: data.bio || '',
            location: data.location || '',
            website: data.website || '',
            socials: {
              twitter: data.socials?.twitter || '',
              facebook: data.socials?.facebook || '',
              instagram: data.socials?.instagram || '',
              linkedin: data.socials?.linkedin || ''
            }
          };
        }

        setProfileData(normalizedData);
        setEditState(normalizedData);
      } catch (err) {
        console.error('Error fetching profile:', err);
        // Even on error, set default empty profile
        const defaultProfile = {
          bio: '',
          location: '',
          website: '',
          socials: {
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: ''
          }
        };
        setProfileData(defaultProfile);
        setEditState(defaultProfile);
        setError('Failed to load profile data');
        onError?.(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [user, onError]);

  const validateForm = () => {
    const errors = {};

    if (!validators.bio(editState.bio)) {
      errors.bio = 'Bio must be between 50 and 400 characters';
    }
    if (!validators.location(editState.location)) {
      errors.location = 'Location cannot exceed 100 characters';
    }
    if (editState.website && !validators.url(editState.website)) {
      errors.website = 'Invalid website URL';
    }

    Object.entries(editState.socials).forEach(([platform, url]) => {
      if (url && !validators.url(url)) {
        errors[platform] = `Invalid ${platform} URL`;
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const response = await fetch(
        `https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: user?.username,
            ...editState
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save profile');
      }

      setProfileData(editState);
      setIsEditing(false);
      setValidationErrors({});
    } catch (err) {
      console.error('Error saving profile:', err);
      setError('Failed to save profile data');
      onError?.(err);
    }
  };

  return (
    <div className="channel-header">
      <div className="banner-section">
        <div className="profile-image-container">
          <img 
            src={DEFAULT_AVATAR}
            alt="Profile" 
            className="profile-image"
          />
        </div>
        
        <div className="banner-content">
          <h1 className="banner-username">
            {user?.channelName || user?.username || "Channel"}
          </h1>
          
          <div className="stats">
            <div className="stat-item">
              <span className="stat-number">{playlists?.length || 0}</span>
              <span className="stat-label">Playlists</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">0</span>
              <span className="stat-label">Followers</span>
            </div>
          </div>
        </div>

        {isOwner && (
          <button
            onClick={() => {
              setIsEditing(true);
              setEditState(profileData);
            }}
            className="customize-button"
          >
            <Edit2 className="icon" />
            Customize
          </button>
        )}
      </div>

      <div className="bio-section">
        {!isEditing ? (
          <div className="group">
            <p>{profileData.bio || "No bio available"}</p>
            {profileData.location && (
              <div className="location-display">
                <Globe className="icon" />
                {profileData.location}
              </div>
            )}
          </div>
        ) : (
          <div className="edit-section">
            <div>
              <textarea
                value={editState.bio}
                onChange={(e) => setEditState(prev => ({ ...prev, bio: e.target.value }))}
                className="bio-input"
                rows={4}
                maxLength={400}
                placeholder="Tell others about yourself..."
              />
              <div className="character-count">
                {editState.bio.length}/400 characters (minimum 50)
              </div>
            </div>
            
            <input
              type="text"
              value={editState.location}
              onChange={(e) => setEditState(prev => ({ ...prev, location: e.target.value }))}
              className="location-input"
              placeholder="Location (optional)"
            />

            <div className="edit-controls">
              <button
                onClick={handleSave}
                className="edit-button-primary"
              >
                <Check className="icon" />
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                  setEditState(profileData);
                  setValidationErrors({});
                }}
                className="edit-button-secondary"
              >
                <X className="icon" />
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="social-links">
        <div className="social-links-container">
          {Object.entries(profileData.socials || {}).map(([platform, url]) => (
            url && (
              <a
                key={platform}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                {socialPlatforms.find(p => p.id === platform)?.label}
                <ExternalLink className="icon" />
              </a>
            )
          ))}
          {profileData.website && (
            <a
              href={profileData.website}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              Website
              <ExternalLink className="icon" />
            </a>
          )}
        </div>
        
        {isEditing && (
          <div className="social-edit-section">
            {socialPlatforms.map(platform => (
              <input
                key={platform.id}
                type="text"
                value={editState.socials[platform.id] || ''}
                onChange={(e) => setEditState(prev => ({
                  ...prev,
                  socials: {
                    ...prev.socials,
                    [platform.id]: e.target.value
                  }
                }))}
                className="social-input"
                placeholder={`${platform.label} profile URL`}
              />
            ))}
            
            <input
              type="text"
              value={editState.website || ''}
              onChange={(e) => setEditState(prev => ({ ...prev, website: e.target.value }))}
              className="social-input"
              placeholder="Website URL"
            />
          </div>
        )}
      </div>

      <div className="playlist-section">
        <select
          value={playlistName}
          onChange={onPlaylistChange}
          className="playlist-select"
        >
          <option value="">All Playlists</option>
          {playlists?.map((playlist, index) => (
            <option key={index} value={playlist}>
              {playlist}
            </option>
          ))}
        </select>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {Object.keys(validationErrors).length > 0 && (
        <div className="error-message">
          Please fix the following errors:
          <ul className="list-disc list-inside">
            {Object.entries(validationErrors).map(([field, error]) => (
              <li key={field}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChannelHeader;