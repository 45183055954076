import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Navbar.css';

const Navbar = ({ onSignInClick, onSignUpClick }) => {
  const { isAuthenticated, signOut, user } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const userUsername = user.username || 
                          user.attributes?.username || 
                          user.signInUserSession?.idToken?.payload?.username || 
                          user.signInUserSession?.idToken?.payload['cognito:username'];
      
      if (userUsername) {
        setUsername(userUsername);
      }
    }
  }, [user]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut();
    closeMobileMenu();
    navigate('/');
  };

  const handleMyChannelClick = (e) => {
    if (!username) {
      e.preventDefault();
      return;
    }
    closeMobileMenu();
  };

  return (
    <div className="nav-container">
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/" onClick={closeMobileMenu}>Biglio</Link>
        </div>
        <div className="navbar-toggle" onClick={toggleMobileMenu}>
          <span className="navbar-toggle-icon">&#9776;</span>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={closeMobileMenu}>HOME</Link></li>
          <li><Link to="/create" onClick={closeMobileMenu}>CREATE</Link></li>
          {isAuthenticated && username && (
            <>
              <li>
                <Link 
                  to={`/channel/${username}`}
                  onClick={handleMyChannelClick}
                  className="nav-link"
                >
                  MY CHANNEL
                </Link>
              </li>
              <li><button onClick={handleSignOut} className="nav-button">SIGN OUT</button></li>
            </>
          )}
          {!isAuthenticated && (
            <li>
              <button 
                onClick={() => { onSignInClick(); closeMobileMenu(); }}
                className="try-free-button"
              >
                Try Free
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;