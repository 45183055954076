import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { AuthContext } from '../../context/AuthContext';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
// Removed TypingIndicator import
import SignInModal from '../../components/SignInModal';
import '../styles/ChatContainer.css';

const ChatContainer = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);

  // Folder and project management
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [isFolderSectionOpen, setIsFolderSectionOpen] = useState(false);
  const [folderChats, setFolderChats] = useState([]);
  const [loadingChats, setLoadingChats] = useState(false);

  // Starred messages management
  const [starredMessages, setStarredMessages] = useState({});

  // Fetch folders when component mounts
  useEffect(() => {
    if (user?.userid) {
      fetchFolders();
    }
  }, [user?.userid]);

  const fetchFolders = async () => {
    try {
      const response = await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getFolders',
          userId: user.userid
        })
      });
      const data = await response.json();
      setFolders(data.folders || []);
    } catch (err) {
      setError('Failed to fetch folders');
      console.error('Error fetching folders:', err);
    }
  };

  const createFolder = async () => {
    if (!newFolderName.trim()) return;
    
    try {
      const response = await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'createFolder',
          userId: user.userid,
          folderName: newFolderName
        })
      });
      const data = await response.json();
      
      // Add new folder to folders list
      const newFolder = {
        ...data,
        folderName: newFolderName,
        folderId: data.folderId
      };
      setFolders([...folders, newFolder]);
      
      // Clear input
      setNewFolderName('');
      
      // Automatically select the new folder
      setSelectedFolder(newFolder);
      await handleFolderSelect(newFolder.folderId); // Ensure the folder is selected and chats are loaded
    } catch (err) {
      setError('Failed to create folder');
      console.error('Error creating folder:', err);
    }
  };

  const fetchFolderChats = async (folderId) => {
    setLoadingChats(true);
    try {
      console.log('Fetching chats for folder:', folderId); // Debug log
      const response = await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getConversationsByFolder',
          userId: user.userid,
          folderId
        })
      });
      const data = await response.json();
      console.log('Received folder chats:', data); // Debug log
  
      if (!response.ok) {
        throw new Error(`Server error: ${data.error || response.status}`);
      }
  
      // Ensure each conversation has required fields
      const validatedChats = (data.conversations || []).filter(chat => chat.conversationId).map(chat => ({
        conversationId: chat.conversationId,
        title: chat.title || 'Untitled Conversation',
        createdAt: chat.createdAt || Date.now(),
        folderId: chat.folderId
      }));
  
      console.log('Validated chats:', validatedChats); // Debug log
      setFolderChats(validatedChats);
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError('Failed to fetch conversations');
    } finally {
      setLoadingChats(false);
    }
  };

  const loadConversationMessages = async (conversationId) => {
    if (!user?.userid || !conversationId) {
      console.error('Missing required params:', { userId: user?.userid, conversationId });
      setError('Missing required information to load conversation');
      return;
    }

    setLoadingMessages(true);
    setError(null);
    
    try {
      console.log('Loading conversation:', { userId: user.userid, conversationId });
      const response = await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getConversationMessages',
          userId: user.userid,
          conversationId: conversationId
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(`Server error: ${data.error || response.status}`);
      }

      if (!data.messages) {
        throw new Error('No messages received from server');
      }

      console.log('Received messages:', data);
      setMessages(data.messages);
      setConversationId(conversationId);
      setSelectedConversation(data.metadata);

      // Update starred messages state
      const newStarredMessages = data.messages.reduce((acc, msg) => {
        acc[msg.id] = msg.isStarred;
        return acc;
      }, {});
      setStarredMessages(newStarredMessages);
      
    } catch (err) {
      console.error('Error loading conversation details:', {
        error: err,
        userId: user?.userid,
        conversationId
      });
      setError(`Failed to load conversation messages: ${err.message || 'Unknown error'}`);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleLoadConversation = async (chat) => {
    if (!chat?.conversationId) {
      console.error('Invalid chat object:', chat);
      setError('Cannot load conversation - missing conversation ID');
      return;
    }

    if (!user?.userid) {
      console.error('No user ID available');
      setError('Cannot load conversation - user not authenticated');
      return;
    }

    // Clear current messages and reset states
    setMessages([]);
    setCurrentStreamingMessage('');
    setError(null);
    
    console.log('Loading conversation:', chat);
    await loadConversationMessages(chat.conversationId);
  };

  const handleFolderSelect = async (folderId) => {
    const folder = folders.find(f => f.folderId === folderId);
    if (!folder) {
      console.error('Folder not found:', folderId);
      setError('Selected folder not found');
      return;
    }
    
    setSelectedFolder(folder);
    setMessages([]); // Clear current messages
    setSelectedConversation(null); // Clear selected conversation
    try {
      await fetchFolderChats(folderId);
    } catch (err) {
      console.error('Error fetching folder chats:', err);
      setError('Failed to load chats for the selected folder');
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim() || !user) return;
  
    setError(null);
    setMessages(prev => [...prev, {
      id: Date.now(),
      role: 'user',
      content: message,
      folderId: selectedFolder?.folderId || 'default'
    }]);
  
    setIsLoading(true);
    setCurrentStreamingMessage('');
  
    try {
      const response = await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'sendMessage',
          message,
          userId: user.userid,
          conversationId,
          folderId: selectedFolder?.folderId || 'default'
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
      }
  
      const reader = response.body.getReader();
      let messageId = null;
      let accumulatedMessage = '';
  
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        const chunk = new TextDecoder().decode(value);
        const data = JSON.parse(chunk);
  
        if (data.chunk) {
          accumulatedMessage += data.chunk;
          setCurrentStreamingMessage(accumulatedMessage);
          messageId = data.messageId;
        }
  
        if (data.done) {
          setMessages(prev => [...prev, {
            id: messageId || Date.now(),
            role: 'assistant',
            content: accumulatedMessage,
            folderId: selectedFolder?.folderId || 'default'
          }]);
          setCurrentStreamingMessage('');
          if (!conversationId) {
            setConversationId(data.conversationId);
          }
          break;
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError(`Failed to send message: ${error.message}`);
      setMessages(prev => [...prev, {
        id: Date.now(),
        role: 'error',
        content: 'Failed to send message. Please try again.'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStarMessage = async (messageId) => {
    // Update UI immediately
    setStarredMessages(prev => ({
      ...prev,
      [messageId]: !prev[messageId]
    }));
    
    // Store in DynamoDB
    try {
      await fetch('https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'starMessage',
          userId: user.userid,
          messageId,
          conversationId,
          starred: !starredMessages[messageId]
        })
      });
    } catch (err) {
      console.error('Error starring message:', err);
      // Revert UI if failed
      setStarredMessages(prev => ({
        ...prev,
        [messageId]: !prev[messageId]
      }));
    }
  };

  const copyMessage = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy message:', err);
      setError('Failed to copy message');
    }
  };

  const scrollToStarredMessage = (messageId) => {
    const element = document.getElementById(`message-${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.classList.add('highlight-message');
      setTimeout(() => element.classList.remove('highlight-message'), 2000);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentStreamingMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!isAuthenticated) {
    return (
      <div>
        <button onClick={() => setShowSignInModal(true)}>Sign In to Access Chat</button>
        {showSignInModal && <SignInModal onClose={() => setShowSignInModal(false)} />}
      </div>
    );
  }

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="header-content">
          <h2>Hi! I'm Biglio. How can I help you today?</h2>
          <button
            className="toggle-folder-button"
            onClick={() => setIsFolderSectionOpen(!isFolderSectionOpen)}
          >
            {isFolderSectionOpen ? 'Hide Folders' : 'Show Folders'}
          </button>
        </div>
        {selectedFolder && (
          <div className="current-folder-indicator">
            Currently in: <span className="folder-name">{selectedFolder.folderName}</span>
          </div>
        )}
        {error && (
          <div className="error-banner">
            {error}
            <button onClick={() => setError(null)} className="error-dismiss">
              ×
            </button>
          </div>
        )}
      </div>

      <div className="chat-layout">
        {isFolderSectionOpen && (
          <div className="folder-sidebar">
            <div className="folder-management">
              <h3>Manage Folders</h3>
              <div className="folder-list">
                <select 
                  value={selectedFolder?.folderId || ''} 
                  onChange={(e) => handleFolderSelect(e.target.value)}
                  className="folder-select"
                >
                  <option value="">Select Folder</option>
                  {folders.map((folder) => (
                    <option key={folder.folderId} value={folder.folderId}>
                      {folder.folderName}
                    </option>
                  ))}
                </select>
                
                <div className="new-folder-input">
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder="New folder name"
                    className="folder-input"
                  />
                  <button onClick={createFolder} className="create-folder-btn">
                    Create Folder
                  </button>
                </div>
              </div>

              {selectedFolder && (
                <div className="folder-contents">
                  <h4>Conversations in {selectedFolder.folderName}</h4>
                  {loadingChats ? (
                    <div>Loading conversations...</div>
                  ) : (
                    <div className="chat-list">
                      {folderChats.length > 0 ? (
                        folderChats.map((chat) => (
                          <div 
                            key={chat.conversationId} 
                            className={`chat-item ${selectedConversation?.conversationId === chat.conversationId ? 'selected' : ''}`}
                            onClick={() => handleLoadConversation(chat)}
                          >
                            <div className="chat-title">{chat.title}</div>
                            <div className="chat-date">
                              {new Date(chat.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-chats">
                          No conversations in this folder
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <StarredMessagesList 
              starredMessages={starredMessages} 
              messages={messages} 
              scrollToStarredMessage={scrollToStarredMessage} 
            />
            <button
              className="toggle-folder-button"
              onClick={() => setIsFolderSectionOpen(false)}
            >
              Hide Folders
            </button>
          </div>
        )}

        <div className="main-chat-section">
          <div className="messages-container">
            {loadingMessages ? (
              <div className="loading-messages">Loading conversation...</div>
            ) : (
              <>
                <div className="messages">
                  {messages.map((message) => (
                    <div key={message.id} id={`message-${message.id}`} className={`message ${message.role}`}>
                      <div className="message-content">
                        {message.role === 'assistant' ? (
                          <ReactMarkdown>{message.content}</ReactMarkdown>
                        ) : (
                          message.content
                        )}
                        <div className="message-actions">
                          <button 
                            className={`star-button ${starredMessages[message.id] ? 'starred' : ''}`}
                            onClick={() => handleStarMessage(message.id)}
                          >
                            {starredMessages[message.id] ? '⭐' : '☆'}
                          </button>
                          {message.role === 'assistant' && (
                            <button
                              className="copy-icon"
                              onClick={() => copyMessage(message.content)}
                              title="Copy message"
                            >
                              📋
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {currentStreamingMessage && (
                    <div className="message assistant">
                      <div className="message-content">
                        <ReactMarkdown>{currentStreamingMessage}</ReactMarkdown>
                      </div>
                    </div>
                  )}
                </div>
                <div ref={messagesEndRef} />
                {isLoading && !currentStreamingMessage && (
                  <div className="loading-message">Biglio is working on it<span className="ellipsis">...</span></div>
                )}
                {/* Removed TypingIndicator component */}
              </>
            )}
          </div>

          <ChatInput
            onSendMessage={handleSendMessage}
            disabled={isLoading || loadingMessages}
            placeholder="Type your message here..."
          />
        </div>
      </div>
    </div>
  );
};

const StarredMessagesList = ({ starredMessages, messages, scrollToStarredMessage }) => (
  <div className="starred-messages">
    <h4>Starred Messages</h4>
    {Object.entries(starredMessages)
      .filter(([_, isStarred]) => isStarred)
      .map(([messageId]) => {
        const message = messages.find(m => m.id === messageId);
        return message ? (
          <div 
            key={messageId} 
            className="starred-message-preview"
            onClick={() => scrollToStarredMessage(messageId)}
          >
            {message.content ? message.content.slice(0, 50) : 'No content available'}...
          </div>
        ) : null;
      })}
  </div>
);

export default ChatContainer;