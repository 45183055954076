// MessageItem.js
import React from 'react';
import '../styles/MessageItem.css';

const MessageItem = ({ role, content }) => {
  return (
    <div className={`message-bubble ${role}`}>
      {content}
    </div>
  );
};

export default MessageItem;