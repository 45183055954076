import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ChannelHeader from './ChannelHeader';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';
import { QrCode } from 'lucide-react';
import { FaPlus, FaMinus, FaPlay, FaPause } from 'react-icons/fa';
import XIcon from './site_images/x-icon.svg.webp';
import SMSIcon from './site_images/sms-icon.png';
import PlaceholderImage from './site_images/player_placeholder.png';
import QRCodeModal from './QRCodeModal';
import '../styles/ChannelPage.css';

const ITEMS_PER_PAGE = 10;
const AUDIO_PLAY_EVENT = 'audioPlayEvent';
const BRANDING_AUDIO_URL = 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/biglio_open_v1.mp3';

const ChannelPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useContext(AuthContext);
  const [audioFiles, setAudioFiles] = useState([]);
  const [displayedAudioFiles, setDisplayedAudioFiles] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [channelUser, setChannelUser] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [audioStates, setAudioStates] = useState({});
  const audioRef = useRef(null);
  const brandingAudioRef = useRef(new Audio(BRANDING_AUDIO_URL));

  const isOwner = isAuthenticated && user?.username === username;

  const PUBLIC_API = 'https://utru71xp8h.execute-api.us-east-1.amazonaws.com/prod';
  const PRIVATE_API = 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod';
  const PLAYLIST_API = 'https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod';

  const fetchChannelUser = useCallback(async () => {
    try {
      const response = await fetch(`${PUBLIC_API}/users/lookup?username=${username}`);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Channel not found');
        }
        throw new Error('Error looking up user');
      }
      const userData = await response.json();
      setChannelUser(userData);
      return userData.userid;
    } catch (err) {
      console.error('Error fetching channel user:', err);
      setError('Channel not found');
      return null;
    }
  }, [username]);

  const fetchUserAudioFiles = useCallback(async (userId) => {
    if (!userId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${PRIVATE_API}/audio-files-for-my-profile?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch audio files: ${response.status}`);
      }
      const data = await response.json();
      const sortedFiles = data
        .map(file => ({
          ...file,
          isLoaded: false,
          topic: file.topic.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
        }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAudioFiles(sortedFiles);
      updateDisplayedFiles(sortedFiles, 1);
    } catch (error) {
      console.error('Error fetching audio files:', error);
      setError(`Failed to fetch audio files. ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlaylists = useCallback(async (userId) => {
    if (!userId) return;
    try {
      const response = await fetch(`${PLAYLIST_API}/playlists?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch playlists: ${response.status}`);
      }
      const data = await response.json();
      setPlaylists(data.playlists || []);
    } catch (error) {
      console.error('Error fetching playlists:', error);
      setPlaylists([]);
    }
  }, []);

  useEffect(() => {
    const initializeChannel = async () => {
      setLoading(true);
      setError(null);
      try {
        let userId;
        if (isOwner && user?.userid) {
          userId = user.userid;
          setChannelUser({
            username: user.username,
            userid: user.userid,
            accessToken: user.accessToken
          });
        } else {
          userId = await fetchChannelUser();
        }
        if (userId) {
          await Promise.all([
            fetchUserAudioFiles(userId),
            fetchPlaylists(userId)
          ]);
        }
      } catch (err) {
        console.error('Error initializing channel:', err);
        setError('Failed to load channel');
      } finally {
        setLoading(false);
      }
    };
    if (username) {
      initializeChannel();
    }
  }, [username, isOwner, user, fetchChannelUser, fetchUserAudioFiles, fetchPlaylists]);

  const updateDisplayedFiles = (files, page, selectedPlaylist = playlistName) => {
    const filteredFiles = selectedPlaylist
      ? files.filter(file => file.playlistName === selectedPlaylist)
      : files;
    const startIndex = 0;
    const endIndex = page * ITEMS_PER_PAGE;
    const newDisplayedFiles = filteredFiles.slice(startIndex, endIndex).map(file => ({
      ...file,
      isLoaded: true,
      shareUrl: `/channel/${username}/audio/${file.id}`
    }));
    setDisplayedAudioFiles(newDisplayedFiles);
    setHasMore(endIndex < filteredFiles.length);
    setCurrentPage(page);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    updateDisplayedFiles(audioFiles, nextPage);
  };

  const handlePlaylistChange = (e) => {
    const newPlaylistName = e.target.value;
    setPlaylistName(newPlaylistName);
    setCurrentPage(1);
    updateDisplayedFiles(audioFiles, 1, newPlaylistName);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedFiles = [...audioFiles].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    updateDisplayedFiles(sortedFiles, 1);
  };

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const preloadAudioDurations = () => {
    displayedAudioFiles.forEach(file => {
      const audio = new Audio(file.audioUrl);
      audio.addEventListener('loadedmetadata', () => {
        setAudioStates(prev => ({
          ...prev,
          [file.id]: { ...prev[file.id], duration: audio.duration }
        }));
      });
    });
  };

  useEffect(() => {
    preloadAudioDurations();
  }, [displayedAudioFiles]);

  const renderAudioItem = (file) => {
    const shareUrl = `${window.location.origin}/audio-player?audioUrl=${encodeURIComponent(file.audioUrl)}&topic=${encodeURIComponent(file.topic)}&username=${encodeURIComponent(file.username)}`;
    const shareTitle = file.topic || 'Untitled Audio';
    const audioState = audioStates[file.id] || { currentTime: 0, duration: 0 };
  
    return (
      <React.Fragment key={file.id}>
       <tr className="audio-item">
  <td className="play-cell">
  <button 
  onClick={() => playPauseAudio(file.id, file.audioUrl)} 
  className="play-button"
>
  {playingAudioId === file.id ? (
    <FaPause className="play-icon" />
  ) : (
    <FaPlay className="play-icon" />
  )}
</button>
  </td>
  <td className="title-cell">{file.topic}</td>
  <td className="expand-cell">
  <button 
  onClick={() => toggleRowExpansion(file.id)} 
  className="details-button"
>
  {expandedRows[file.id] ? (
    <FaMinus className="details-icon" />
  ) : (
    <FaPlus className="details-icon" />
  )}
</button>
  </td>
</tr>
        <tr>
          <td colSpan="3">
            <div className="progress-container">
              <div 
                className="progress-bar" 
                style={{ 
                  width: `${(audioState.currentTime / audioState.duration) * 100 || 0}%` 
                }}
              />
            </div>
          </td>
        </tr>
        {expandedRows[file.id] && (
          <tr>
            <td colSpan="3" className="expanded-content">
              <div style={{ marginBottom: '10px' }}>
                <strong>Summary:</strong> {file.summary || 'No summary available'}
              </div>
              <div className="share-buttons" style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                  <FacebookIcon size={34} round />
                </FacebookShareButton>
                <a href={`https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`} target="_blank" rel="noopener noreferrer" aria-label="Share on X">
                  <img src={XIcon} alt="" style={{ width: 36, height: 36, borderRadius: '50%' }} />
                </a>
                <LinkedinShareButton url={shareUrl} title={shareTitle}>
                  <LinkedinIcon size={34} round />
                </LinkedinShareButton>
                <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out this audio: ${shareTitle}`}>
                  <EmailIcon size={34} round />
                </EmailShareButton>
                <a href={`sms:?&body=${encodeURIComponent(shareTitle + ' ' + shareUrl)}`} target="_blank" rel="noopener noreferrer" aria-label="Share via SMS">
                  <img src={SMSIcon} alt="" style={{ width: 34, height: 34, borderRadius: '50%' }} />
                </a>
                <button 
                  onClick={() => setShowQRCode(true)}
                  className="qr-button"
                  aria-label="Show QR Code"
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                >
                  <QrCode size={34} color="white" />
                </button>
              </div>
              {showQRCode && (
                <QRCodeModal
                  url={shareUrl}
                  onClose={() => setShowQRCode(false)}
                />
              )}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  const playPauseAudio = (id, url) => {
    if (playingAudioId === id) {
      audioRef.current.pause();
      setPlayingAudioId(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      brandingAudioRef.current.play().then(() => {
        brandingAudioRef.current.onended = () => {
          const audio = new Audio(url);
          audioRef.current = audio;
          audio.play();
          setPlayingAudioId(id);

          audio.addEventListener('loadedmetadata', () => {
            setAudioStates(prev => ({
              ...prev,
              [id]: { ...prev[id], duration: audio.duration }
            }));
          });

          audio.addEventListener('timeupdate', () => {
            setAudioStates(prev => ({
              ...prev,
              [id]: { ...prev[id], currentTime: audio.currentTime }
            }));
          });
        };
      }).catch(error => {
        console.error('Branding audio playback failed:', error);
        setError('Playback failed. Please try again.');
      });
    }
  };

  useEffect(() => {
    const handleOtherPlayerStart = (event) => {
      if (event.detail.playerId !== playingAudioId) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        brandingAudioRef.current.pause();
        setPlayingAudioId(null);
      }
    };

    window.addEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
    return () => window.removeEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
  }, [playingAudioId]);

  if (loading) {
    return <div className="loading">Loading channel...</div>;
  }

  if (error) {
    return (
      <div className="error">
        <p>{error}</p>
        <button 
          onClick={() => navigate('/')} 
          className="return-home-button"
        >
          Return Home
        </button>
      </div>
    );
  }

  return (
    <div className="channel-page">
      <ChannelHeader 
        user={channelUser}
        playlists={playlists}
        onPlaylistChange={handlePlaylistChange}
        playlistName={playlistName}
        isOwner={isOwner}
        onError={(err) => {
          console.error('Profile error:', err);
          setError(err.message || 'An error occurred with the profile');
        }}
      />
      <main>
        <section>
          {loading && <div className="loading">Loading...</div>}
          {error && <p className="error-message">{error}</p>}
          {!loading && !error && displayedAudioFiles.length === 0 && (
            <p className="no-audio-message">
              {isOwner 
                ? "You haven't uploaded any audio files yet."
                : "This channel has no audio files yet."}
            </p>
          )}
          <table className="audio-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('topic')}>Title</th>
                <th onClick={() => handleSort('duration')}>Duration</th>
                <th onClick={() => handleSort('createdAt')}>Date</th>
              </tr>
            </thead>
            <tbody>
              {!loading && displayedAudioFiles.map((file) => renderAudioItem(file))}
            </tbody>
          </table>
          {hasMore && !loading && (
            <div className="load-more-container">
              <button onClick={loadMore} className="load-more-button">
                Load More
              </button>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default ChannelPage;