import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { UserCircle, QrCode } from 'lucide-react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';
import XIcon from './site_images/x-icon.svg.webp';
import SMSIcon from './site_images/sms-icon.png';
import PlaceholderImage from './site_images/player_placeholder.png';
import QRCodeModal from './QRCodeModal';
import '../styles/FlexibleAudioPlayerV2.css';

const BRANDING_AUDIO_URL = 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/biglio_open_v1.mp3';
const AUDIO_PLAY_EVENT = 'audioPlayEvent';

const FlexibleAudioPlayer = ({ tracks = [] }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [isBrandingPlayed, setIsBrandingPlayed] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const audioRef = useRef(null);
  const brandingAudioRef = useRef(null);
  const playerIdRef = useRef(`player-${Math.random()}`);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, []);

  const handleError = useCallback((e) => {
    console.error('Audio error:', e);
    setError('An error occurred while playing the audio. Please try again.');
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const handleOtherPlayerStart = (event) => {
      if (event.detail.playerId !== playerIdRef.current && isPlaying) {
        audioRef.current?.pause();
        brandingAudioRef.current?.pause();
        setIsPlaying(false);
      }
    };

    window.addEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
    return () => window.removeEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const brandingAudio = brandingAudioRef.current;
    if (!audio || !brandingAudio) return;

    const handleBrandingEnd = () => {
      setIsBrandingPlayed(true);
      audio.play().then(() => {
        setIsPlaying(true);
        window.dispatchEvent(new CustomEvent(AUDIO_PLAY_EVENT, {
          detail: { playerId: playerIdRef.current }
        }));
      }).catch(error => {
        console.error('Main audio playback failed:', error);
        setError('Playback failed. Please try again.');
        setIsPlaying(false);
      });
    };

    brandingAudio.addEventListener('ended', handleBrandingEnd);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('error', handleError);
    brandingAudio.addEventListener('error', handleError);

    return () => {
      brandingAudio.removeEventListener('ended', handleBrandingEnd);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('error', handleError);
      brandingAudio.removeEventListener('error', handleError);
    };
  }, [handleLoadedMetadata, handleTimeUpdate, handleError]);

  useEffect(() => {
    if (tracks.length > 0 && audioRef.current) {
      audioRef.current.src = tracks[currentTrackIndex].audioUrl;
      audioRef.current.load();
    }
  }, [tracks, currentTrackIndex]);

  const handlePlayPause = useCallback((e) => {
    if (e.type === 'click' && e.detail === 0) return;
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      brandingAudioRef.current?.pause();
      setIsPlaying(false);
    } else {
      window.dispatchEvent(new CustomEvent(AUDIO_PLAY_EVENT, {
        detail: { playerId: playerIdRef.current }
      }));

      if (!isBrandingPlayed && brandingAudioRef.current) {
        brandingAudioRef.current.play().catch(handleError);
      } else {
        audioRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch(handleError);
      }
    }
  }, [isPlaying, isBrandingPlayed, handleError]);

  const handleSeek = useCallback((e) => {
    if (!audioRef.current) return;
    
    if (e.type.includes('touch')) {
      e.preventDefault();
    }
    
    const rect = e.target.getBoundingClientRect();
    const clientX = e.type.includes('touch') 
      ? e.touches[0].clientX 
      : e.clientX;
    
    const offsetX = Math.min(Math.max(0, clientX - rect.left), rect.width);
    const newTime = (offsetX / rect.width) * duration;
    
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, [duration]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  if (tracks.length === 0) {
    return <div className="no-tracks">No tracks available</div>;
  }

  const currentTrack = tracks[currentTrackIndex];
  const shareUrl = `${window.location.origin}/audio-player?audioUrl=${encodeURIComponent(currentTrack.audioUrl)}&topic=${encodeURIComponent(currentTrack.topic)}&username=${encodeURIComponent(currentTrack.username)}`;
  const shareTitle = currentTrack.topic || 'Untitled Audio';
  
  return (
    <>
      <Helmet>
        <meta property="og:title" content={currentTrack.topic} />
        <meta property="og:description" content={`Listen to ${currentTrack.username}'s audio`} />
        <meta property="og:image" content={currentTrack.imageUrl || PlaceholderImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="music.song" />
        <meta property="og:audio" content={currentTrack.audioUrl} />
        <meta property="og:audio:type" content="audio/mpeg" />
        
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={currentTrack.topic} />
        <meta name="twitter:description" content={`Listen to ${currentTrack.username}'s audio`} />
        <meta name="twitter:image" content={currentTrack.imageUrl || PlaceholderImage} />
        <meta name="twitter:player" content={shareUrl} />
        <meta name="twitter:player:width" content="480" />
        <meta name="twitter:player:height" content="120" />
      </Helmet>

      <div className="audio-player" role="region" aria-label="Audio player">
        <img src={PlaceholderImage} alt="" className="audio-placeholder" />
        <div className="audio-details">
          <h3 className="track-title">{currentTrack.topic}</h3>
          <div className="channel-link-container">
            <Link 
              to={`/channel/${currentTrack.username}`} 
              className="channel-link-inner"
            >
              <UserCircle size={18} style={{ opacity: 0.7 }} />
              <span className="username">{currentTrack.username}</span>
            </Link>
          </div>
          <audio ref={brandingAudioRef} src={BRANDING_AUDIO_URL} preload="auto" />
          <audio ref={audioRef} preload="auto" />
          {error && <div className="error-message" role="alert">{error}</div>}
          <div className="controls">
            <button
              onClick={handlePlayPause}
              className="custom-play-button" 
              aria-label={isPlaying ? 'Pause' : 'Play'}
            >
              {isPlaying ? 'Pause' : 'Play'}
            </button>
          </div>
          <div className="seek-bar-container">
            <div className="time-display">
              <span className="current-time">{formatTime(currentTime)}</span>
              <span className="total-time">{formatTime(duration)}</span>
            </div>
            <div 
              className="progress-track" 
              onClick={handleSeek}
              onTouchStart={handleSeek}
              onTouchMove={handleSeek}
              onTouchEnd={(e) => e.preventDefault()}
              role="slider"
              aria-label="Seek audio"
              aria-valuemin="0"
              aria-valuemax={duration}
              aria-valuenow={currentTime}
            >
              <div 
                className="progress-bar" 
                style={{ width: `${(currentTime / duration) * 100}%` }}
              ></div>
              <div 
                className="seek-thumb" 
                style={{ left: `${(currentTime / duration) * 100}%` }}
              ></div>
            </div>
          </div>

          <div className="button-container">
            {currentTrack.summary && currentTrack.summary !== null && (
              <button 
                onClick={() => setShowSummary(!showSummary)}
                className="summary-button"
              >
                Summary
              </button>
            )}
            <button 
              onClick={() => setShowShareButtons(!showShareButtons)}
              className="share-toggle-button"
              aria-expanded={showShareButtons}
              aria-controls="share-buttons"
            >
              Share
            </button>
          </div>

          <div 
            className="share-buttons-container"
            style={{ height: showShareButtons ? 'auto' : '0' }}
          >
            <div className={`share-buttons ${showShareButtons ? '' : 'collapsed'}`}>
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <a href={`https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`} target="_blank" rel="noopener noreferrer" aria-label="Share on X">
                <img src={XIcon} alt="" style={{ width: 32, height: 32, borderRadius: '50%' }} />
              </a>
              <LinkedinShareButton url={shareUrl} title={shareTitle}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out this audio: ${shareTitle}`}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <a href={`sms:?&body=${encodeURIComponent(shareTitle + ' ' + shareUrl)}`} target="_blank" rel="noopener noreferrer" aria-label="Share via SMS">
                <img src={SMSIcon} alt="" style={{ width: 32, height: 32, borderRadius: '50%' }} />
              </a>
              <button 
                onClick={() => setShowQRCode(true)}
                className="qr-button"
                aria-label="Show QR Code"
              >
                <QrCode size={32} color="white" />
              </button>
            </div>
          </div>

          {showQRCode && (
            <QRCodeModal
              url={shareUrl}
              onClose={() => setShowQRCode(false)}
            />
          )}

          {currentTrack.summary && (
            <div 
              className="summary-container"
              style={{ height: showSummary ? 'auto' : '0' }}
            >
              <div className={`summary-content ${showSummary ? '' : 'collapsed'}`}>
                <div className="mt-4 text-white text-sm p-4 bg-black/30 rounded">
                  {currentTrack.summary}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FlexibleAudioPlayer;